import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importing Components
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent'; // Import CookieConsent Component
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop Component

// Importing Pages
import Home from './pages/Home';
import MACLookup from './pages/MACLookup';
import SubnetCalculator from './pages/SubnetCalculator';
import WhoisLookup from './pages/WhoisLookup';
import ReverseDNSLookup from './pages/ReverseDNSLookup';
import HTTPHeaderChecker from './pages/HTTPHeaderChecker';
import URLEncoderDecoder from './pages/URLEncoderDecoder';
import Base64EncoderDecoder from './pages/Base64EncoderDecoder';
import DNSLookup from './pages/DNSLookup';
import PingTest from './pages/PingTest';
import PortChecker from './pages/PortChecker';
import AboutUs from './pages/AboutUs'; 
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';




// Importing Global Styles
import './assets/styles/site_global.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <div className="App">
        {/* Header Component */}
        <Header />

        {/* CookieConsent Banner */}
        <CookieConsent />

        {/* Routing Section */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mac-lookup" element={<MACLookup />} />
          <Route path="/subnet-calculator" element={<SubnetCalculator />} />
          <Route path="/whois-lookup" element={<WhoisLookup />} />
          <Route path="/reverse-dns-lookup" element={<ReverseDNSLookup />} />
          <Route path="/http-header-checker" element={<HTTPHeaderChecker />} />
          <Route path="/url-encoder-decoder" element={<URLEncoderDecoder />} />
          <Route path="/base64-encoder-decoder" element={<Base64EncoderDecoder />} />
          <Route path="/dns-lookup" element={<DNSLookup />} />
          <Route path="/ping-test" element={<PingTest />} />
          <Route path="/port-checker" element={<PortChecker />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Fallback Route for Catch-All */}
          <Route path="*" element={<Home />} /> 
        </Routes>

        {/* Footer Component */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
