// ToolCard.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './ToolCard.css'; // Import the styles

const ToolCard = ({ tool }) => {
  // Add a check to prevent undefined link errors
  if (!tool || !tool.link) {
    console.error("Tool data is missing 'link' property:", tool);
    return null; // Return null to prevent rendering an incomplete card
  }

  return (
    <div className="card">
      <Link to={tool.link} aria-label={`Go to ${tool.title} page`}>
        <img src={tool.image} alt={tool.title} />
        <h2>{tool.title}</h2>
        <p>{tool.description}</p>
      </Link>
    </div>
  );
};

export default ToolCard;
