// src/pages/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-header">
        <h1 className="privacy-policy-header h1">PRIVACY POLICY</h1>
        <p className="privacy-policy-text">Your privacy is important to us. This policy outlines how we handle your data.</p>
      </div>
      
      <div className="privacy-policy-content">
        <div className="information-collection-section">
          <h2>Information Collection</h2>
          <p className="privacy-policy-text">We collect information that you provide to us directly, such as when you contact us or use our tools. This may include your name, email address, and any other details you provide. We also collect data automatically when you interact with our services, including your IP address, device information, and how you interact with our website.</p>
        </div>

        <div className="information-use-section">
          <h2>Information Use</h2>
          <p className="privacy-policy-text">The information we collect is used to provide, maintain, and improve our services. We use your information to:</p>
          <ul>
            <li>Respond to your inquiries and provide customer support.</li>
            <li>Improve the features and usability of our tools and website.</li>
            <li>Send updates or newsletters if you opt-in to receive them.</li>
            <li>Comply with legal obligations.</li>
          </ul>
        </div>

        <div className="information-sharing-section">
          <h2>Information Sharing</h2>
          <p className="privacy-policy-text">We do not share your personal data with third parties except as necessary to operate our services, comply with the law, or protect our rights. We may share your data with:</p>
          <ul>
            <li>Service providers that assist us in operating our website and tools.</li>
            <li>Authorities if required by law or as necessary to comply with legal obligations.</li>
          </ul>
        </div>

        <div className="cookies-section">
          <h2>Cookies and Tracking Technologies</h2>
          <p className="privacy-policy-text">We use cookies and similar tracking technologies to understand how you use our website, personalize your experience, and gather analytics data to improve our tools and services. You can control cookies through your browser settings, but disabling cookies may impact the functionality of our website.</p>
        </div>

        <div className="data-security-section">
          <h2>Data Security</h2>
          <p className="privacy-policy-text">We take your privacy seriously and use industry-standard measures to protect your information from unauthorized access, disclosure, or misuse. We implement physical, technical, and administrative safeguards to keep your data secure.</p>
        </div>

        <div className="data-retention-section">
          <h2>Data Retention</h2>
          <p className="privacy-policy-text">We retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy. When it is no longer needed, we securely delete or anonymize your information.</p>
        </div>

        <div className="children-privacy-section">
          <h2>Children's Privacy</h2>
          <p className="privacy-policy-text">Our website and services are not intended for children under 13. We do not knowingly collect personal data from children. If we become aware that a child has provided us with personal information, we will take steps to delete such information.</p>
        </div>

        <div className="user-rights-section">
          <h2>Your Rights</h2>
          <p className="privacy-policy-text">You have the right to access, update, or delete your personal information. If you wish to exercise these rights, please contact us using the information provided below. You may also withdraw your consent for data processing at any time.</p>
        </div>

        <div className="updates-section">
          <h2>Updates to This Policy</h2>
          <p className="privacy-policy-text">We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. The latest version will always be available on our website, and we encourage you to review it regularly.</p>
        </div>

        <div className="contact-section">
          <h2>Contact Us</h2>
          <p className="privacy-policy-text">If you have any questions about our privacy practices, you can contact us via our <a href="/contact">Contact Us form</a>.</p>
          <p className="privacy-policy-text">If you prefer, you can also reach out via email at <a href="mailto:support@tracewarrior.com">support@tracewarrior.com</a>.</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
