// src/pages/ReverseDNSLookup.js
import React, { useState } from 'react';
import ToolCard from '../components/ToolCard';
import './ReverseDNSLookup.css';
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/mac-lookup",
    image: "/images/mac-lookup.png",
    title: "MAC Address Lookup",
    description: "Identify the manufacturer associated with a specific MAC address."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }
];

const faqs = [
  { question: "What is Reverse DNS?", answer: "Reverse DNS (rDNS) is the process of translating an IP address to its associated domain name. This helps in identifying the domain name associated with a given IP address." },
  { question: "Why should I use a Reverse DNS tool?", answer: "A Reverse DNS tool allows you to verify which domain name is associated with an IP address. It is useful for email server verification, network troubleshooting, and security checks." },
  { question: "How accurate is Reverse DNS data?", answer: "The accuracy of Reverse DNS data depends on the correctness of DNS records maintained by the domain owner or ISP. It is typically reliable, but may sometimes be outdated or missing." },
  { question: "Can I perform Reverse DNS on any IP address?", answer: "You can perform Reverse DNS on any public IP address, provided the appropriate PTR records are set up by the IP's owner." },
  { question: "How can the Reverse DNS tool help me?", answer: "This tool helps network administrators verify domain names associated with IP addresses, which is important for troubleshooting network issues and verifying legitimate connections." }
];

const ReverseDNSLookup = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setIpAddress(e.target.value);
    setError(''); // Clear error when user changes input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateIPAddress(ipAddress)) {
      setError('Please enter a valid IPv4 address.');
      return;
    }

    setIsLoading(true);
    setResult('');
    setError('');

    try {
      // Perform a real reverse DNS lookup using Google's DNS API
      const response = await fetch(`https://dns.google/resolve?name=${getReverseIP(ipAddress)}&type=PTR`);
      const data = await response.json();

      if (data && data.Answer && data.Answer.length > 0) {
        const domain = data.Answer[0].data.replace(/\.$/, ''); // Remove trailing period
        setResult(`The domain name for IP address ${ipAddress} is: ${domain}`);
      } else {
        setResult(`No PTR record found for IP address: ${ipAddress}`);
      }
    } catch (error) {
      setError('Error: Unable to perform reverse DNS lookup for the provided IP address.');
    }

    setIsLoading(false);
  };

  // Convert IP address to reverse lookup format (e.g., 192.0.2.1 -> 1.2.0.192.in-addr.arpa)
  const getReverseIP = (ip) => {
    return ip.split('.').reverse().join('.') + '.in-addr.arpa';
  };

  // Updated IP validation function
  const validateIPAddress = (ip) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
    return ipRegex.test(ip.trim());
  };

  return (
    <div className="reverse-dns-container">
      <Helmet>
        <title>Reverse DNS Lookup - Trace Warrior</title>
        <meta name="description" content="Use Reverse DNS Lookup to find the domain name associated with an IP address, making network analysis easier." />
        <link rel="canonical" href="https://tracewarrior.com/reverse-dns-lookup" />
      </Helmet>
      <div className="tool-header">
        <h1>Reverse DNS Look-Up</h1>
        <p>
          Reverse DNS (rDNS) is the process of mapping an IP address back to its associated domain name.
          Unlike a forward DNS lookup which maps a domain to an IP, reverse DNS allows you to verify the identity
          behind an IP address, which is useful in various network troubleshooting and security contexts.
        </p>
      </div>

      <div className="tool-content">
        <form onSubmit={handleSubmit} className="tool-form">
          <label htmlFor="ip-input" className="input-label">
            Enter an IP address (e.g., 192.168.0.1) to find the associated domain name:
          </label>
          <input
            id="ip-input"
            type="text"
            className={`input-field ${error ? 'input-error' : ''}`}
            value={ipAddress}
            onChange={handleInputChange}
            placeholder="Enter IP address, e.g., 192.168.0.1"
            aria-label="Enter IP address for reverse DNS lookup"
          />
          <button className="submit-button" type="submit" disabled={isLoading || !ipAddress}>
            {isLoading ? 'Looking up...' : 'Lookup'}
          </button>
        </form>

        {error && (
          <div className="error-message" aria-live="polite">
            {error}
          </div>
        )}

        {result && (
          <div className="results" aria-live="polite">
            <h2>Result:</h2>
            <textarea className="output-area" readOnly value={result} rows="3" />
          </div>
        )}
      </div>

      <div className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faqs">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Separating the tools grid outside of the main content container */}
      <div className="explore-tools-section standalone">
        <h2>Explore Our Other Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReverseDNSLookup;
