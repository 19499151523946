// src/pages/Base64EncoderDecoder.js
import React, { useState } from 'react';
import ToolCard from '../components/ToolCard'; // Import ToolCard to display other tools
import './Base64EncoderDecoder.css';
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/whois-lookup",
    image: "/images/whois-lookup.png",
    title: "Whois Lookup",
    description: "Retrieve domain registration information."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }
];

const Base64EncoderDecoder = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [isEncoding, setIsEncoding] = useState(true);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleToggle = () => {
    setIsEncoding(!isEncoding);
    setInput('');
    setOutput('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEncoding) {
      setOutput(btoa(input));
    } else {
      try {
        setOutput(atob(input));
      } catch (error) {
        setOutput('Invalid Base64 input');
      }
    }
  };

  return (
    <div className="tool-page-container">
      <Helmet>
        <title>Base64 Encoder/Decoder - Trace Warrior</title>
        <meta name="description" content="Encode or decode data to/from Base64 format using our Base64 Encoder/Decoder tool for easy data transformations." />
        <link rel="canonical" href="https://tracewarrior.com/base64-encoder-decoder" />
      </Helmet>
      <div className="tool-container">
        <div className="tool-header">
          <h1>Base64 Encoder/Decoder Tool</h1>
          <p>Encode or decode text using Base64 encoding. Toggle between encoding and decoding as needed.</p>
        </div>
        
        <div className="tool-content">
          <button className="toggle-button" onClick={handleToggle}>
            {isEncoding ? 'Switch to Decode' : 'Switch to Encode'}
          </button>
          
          <form onSubmit={handleSubmit} className="tool-form">
            <textarea
              className="input-area"
              value={input}
              onChange={handleChange}
              placeholder={isEncoding ? 'Enter text to encode...' : 'Enter Base64 to decode...'}
              rows="5"
            />
            <button className="submit-button" type="submit">{isEncoding ? 'Encode' : 'Decode'}</button>
          </form>

          {output && (
            <div className="results">
              <h2>Result:</h2>
              <textarea className="output-area" readOnly value={output} rows="5" />
            </div>
          )}
        </div>
      </div>

      <div className="explore-tools-section">
        <h2>Explore Our Other Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Base64EncoderDecoder;
