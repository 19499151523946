// src/pages/PingTest.js
import React, { useState } from 'react';
import ToolCard from '../components/ToolCard'; // Import ToolCard component to display tools
import './PingTest.css';
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/whois-lookup",
    image: "/images/whois-lookup.png",
    title: "Whois Lookup",
    description: "Retrieve domain registration information."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }
];

const PingTest = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic for the Ping Test here
  };

  return (
    <div className="tool-page-container">      
      <Helmet>
        <title>Ping Test - Trace Warrior</title>
        <meta name="description" content="Test the reachability of hosts on a network with the Ping Test tool. Diagnose connectivity issues easily." />
        <link rel="canonical" href="https://tracewarrior.com/ping-test" />
      </Helmet>
      <div className="tool-container">
        <div className="tool-header">
          <h1>Ping Test Tool</h1>
          <p>Test the reachability of a host in your network by sending ICMP Echo requests and receiving responses.</p>
        </div>
        
        <div className="tool-content">
          <form onSubmit={handleSubmit} className="tool-form">
            <input
              type="text"
              className="input-area"
              value={input}
              onChange={handleChange}
              placeholder="Enter a hostname or IP address..."
            />
            <button className="submit-button" type="submit">Ping</button>
          </form>

          {output && (
            <div className="results">
              <h2>Result:</h2>
              <p>{output}</p>
            </div>
          )}
        </div>
      </div>

      {/* Add Explore More Tools Section */}
      <div className="explore-tools-section">
        <h2>Explore More Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PingTest;
