// src/pages/HTTPHeaderChecker.js
import React, { useState } from 'react';
import ToolCard from '../components/ToolCard'; // Import ToolCard component to display the grid of other tools
import './HTTPHeaderChecker.css';
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/whois-lookup",
    image: "/images/whois-lookup.png",
    title: "Whois Lookup",
    description: "Retrieve domain registration information."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  },
];

const HTTPHeaderChecker = () => {
  const [url, setUrl] = useState('');
  const [headers, setHeaders] = useState(null);

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const handleCheckHeaders = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/headers?url=${encodeURIComponent(url)}`);
      const data = await response.json();
      setHeaders(data);
    } catch (error) {
      setHeaders({ error: 'Unable to fetch headers. Please try again.' });
    }
  };

  return (
    <div className="tool-page-container">
      
      <div className="tool-container">
      <Helmet>
        <title>HTTP Header Checker - Trace Warrior</title>
        <meta name="description" content="Inspect HTTP headers for troubleshooting web servers and ensuring correct configurations with the HTTP Header Checker tool." />
        <link rel="canonical" href="https://tracewarrior.com/http-header-checker" />
      </Helmet>
        <div className="tool-header">
          <h1>HTTP Header Checker Tool</h1>
          <p>Inspect HTTP headers for troubleshooting web servers.</p>
        </div>
        <div className="tool-content">
          <form onSubmit={handleCheckHeaders} className="tool-form">
            <input
              className="input-area"
              type="text"
              value={url}
              onChange={handleChange}
              placeholder="Enter the URL..."
            />
            <button className="submit-button" type="submit">Check Headers</button>
          </form>
          {headers && (
            <div className="results">
              <h2>Result:</h2>
              <pre className="output-area">{JSON.stringify(headers, null, 2)}</pre>
            </div>
          )}
        </div>
      </div>
      
      <div className="explore-tools-section">
        <h2>Explore Our Other Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HTTPHeaderChecker;
