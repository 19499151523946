// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom'; // Importing Link for navigation
import ToolCard from '../components/ToolCard'; // Import the ToolCard component
import '../assets/styles/site_global.css';
import { Helmet } from 'react-helmet';


const Home = () => {
  const tools = [
    {
      link: "/mac-lookup",
      image: "/images/mac-lookup.png",
      title: "MAC Address Lookup",
      description: "Identify the manufacturer associated with a specific MAC address."
    },
    {
      link: "/subnet-calculator",
      image: "/images/subnet-calculator.png",
      title: "Subnet Calculator",
      description: "Helps divide your network into subnets with ease."
    },
    {
      link: "/whois-lookup",
      image: "/images/whois-lookup.png",
      title: "Whois Lookup",
      description: "Retrieve domain registration information."
    },
    {
      link: "/reverse-dns-lookup",
      image: "/images/reverse-dns.png",
      title: "Reverse DNS Lookup",
      description: "Find the domain name associated with an IP address."
    },
    {
      link: "/http-header-checker",
      image: "/images/http-header-checker.png",
      title: "HTTP Header Checker",
      description: "Inspect HTTP headers for troubleshooting web servers."
    },
    {
      link: "/url-encoder-decoder",
      image: "/images/url-encoder-decoder.png",
      title: "URL Encoder/Decoder",
      description: "Ensure proper URL formatting for readability."
    },
    {
      link: "/ping-test",
      image: "/images/ping-test.png",
      title: "Ping Test",
      description: "Test the reachability of hosts on a network."
    },
    {
      link: "/port-checker",
      image: "/images/port-checker.png",
      title: "Port Checker",
      description: "Check open ports on your server."
    },
    {
      link: "/dns-lookup",
      image: "/images/dns-lookup.png",
      title: "DNS Lookup",
      description: "Retrieve DNS records of a domain."
    },
    {
      link: "/base64-encoder-decoder",
      image: "/images/base64-encoder-decoder.png",
      title: "Base64 Encoder/Decoder",
      description: "Encode or decode data to/from Base64."
    }
  ];

  return (
    <div className="home-container">
      <Helmet>
        <title>Trace Warrior - Comprehensive Network Tools Suite</title>
        <meta name="description" content="Welcome to Trace Warrior - a suite of powerful network tools for diagnostics and network analysis, designed to make networking tasks easier and more efficient." />
        <link rel="canonical" href="https://tracewarrior.com" />
      </Helmet>
      <h1>Welcome to Trace Warrior - Comprehensive Network Tools Suite</h1>
      <h2>Discover a powerful set of network tools designed to make diagnostics easy. From MAC address lookups to DNS checks, find everything you need here.</h2>

      {/* Tool Grid Section */}
      <div className="tool-grid">
        {tools.map((tool, index) => (
          <ToolCard key={index} tool={tool} />
        ))}
      </div>
    </div>
  );
};

export default Home;
