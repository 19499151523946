import React, { useState } from 'react';
import ToolCard from '../components/ToolCard';
import './WhoisLookup.css';
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/mac-lookup",
    image: "/images/mac-lookup.png",
    title: "MAC Address Lookup",
    description: "Identify the manufacturer associated with a specific MAC address."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }
];

const faqs = [
  { question: "What is a Whois lookup?", answer: "A Whois lookup provides detailed information about a domain name, including ownership details, registration dates, and contact information." },
  { question: "Why should I use a Whois Lookup tool?", answer: "Whois Lookup helps you find who owns a domain, check the registration status, and gather contact details. This is especially useful for security checks or understanding domain ownership." },
  { question: "What information can I get from a Whois lookup?", answer: "You can retrieve details like domain owner's name, registrar, creation and expiration dates, and name servers. This can be critical for managing domain disputes or understanding the web's infrastructure." },
  { question: "How accurate is Whois data?", answer: "Whois data accuracy depends on the information provided by domain owners during registration. Generally, it's highly reliable, though it may occasionally be outdated or hidden due to privacy protection services." },
  { question: "Can I hide my domain information from Whois?", answer: "Yes, you can use Whois privacy services offered by most registrars. This helps protect your personal details by masking them from public Whois queries." },
  { question: "How can the Whois Lookup tool help me?", answer: "The Whois Lookup tool helps you investigate domain ownership, confirm domain availability, and detect fraudulent activities, making it an essential resource for web administrators and cybersecurity professionals." }
];

const WhoisLookup = () => {
  const [domain, setDomain] = useState('');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setDomain(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResult('');

    try {
      const response = await fetch('/api/whois', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain: domain }),
      });

      if (response.ok) {
        const data = await response.json();
        setResult(formatWhoisData(data.whois));
      } else {
        const errorData = await response.json();
        setResult(`Error: ${errorData.error}`);
      }
    } catch (error) {
      setResult('Error: Unable to perform WHOIS lookup.');
    }

    setIsLoading(false);
  };

  const formatWhoisData = (whoisData) => {
    let formatted = '';

    for (const key in whoisData) {
      if (whoisData[key] instanceof Array) {
        formatted += `<strong>${capitalize(key)}:</strong><ul>`;
        whoisData[key].forEach((item) => {
          formatted += `<li>${item}</li>`;
        });
        formatted += `</ul>`;
      } else if (whoisData[key]) {
        formatted += `<strong>${capitalize(key)}:</strong> ${whoisData[key]}<br/>`;
      }
    }

    return formatted || 'No WHOIS data available for this domain.';
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).replace('_', ' ');
  };

  return (
    <div className="whois-lookup-page">
      <Helmet>
        <title>Whois Lookup - Trace Warrior</title>
        <meta name="description" content="Use the Whois Lookup tool from Trace Warrior to find domain ownership, registration dates, and contact details to understand more about a website." />
        <link rel="canonical" href="https://tracewarrior.com/whois-lookup" />
      </Helmet>
      {/* Encapsulate the Whois Tool and FAQ together */}
      <div className="tool-and-faq-card">
        {/* Main Tool */}
        <div className="tool-section">
          <div className="tool-header">
            <h1>Whois Lookup</h1>
            <p>Enter a domain name to retrieve its WHOIS registration information.</p>
          </div>

          <div className="tool-content">
            <form onSubmit={handleSubmit} className="tool-form">
              <input
                type="text"
                className="input-field"
                value={domain}
                onChange={handleInputChange}
                placeholder="Enter domain name, e.g., example.com"
              />
              <button className="submit-button" type="submit" disabled={isLoading}>
                {isLoading ? 'Looking up...' : 'Lookup'}
              </button>
            </form>

            {result && (
              <div className="results">
                <h2>Result:</h2>
                <div
                  className="output-area"
                  dangerouslySetInnerHTML={{ __html: result }}
                />
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faqs">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <h3>{faq.question}</h3>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Explore Other Tools Section */}
      <div className="explore-tools-section">
        <h2>Explore Our Other Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhoisLookup;
