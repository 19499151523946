// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-header">
        <h1>About Us</h1>
        <p>Learn more about Trace Warrior and our mission to provide the best network tools for professionals.</p>
      </div>
      
      <div className="about-us-content">
        <div className="mission-section">
          <h2>Our Mission</h2>
          <p>At Trace Warrior, our mission is to empower network administrators and IT professionals with easy-to-use, powerful tools that simplify diagnostics, analysis, and optimization of networks. We believe in making complex tasks easier, enabling professionals to focus on building and maintaining efficient, secure, and reliable network infrastructures.</p>
        </div>
        
        <div className="team-section">
          <h2>Our Team</h2>
          <p>Trace Warrior is the brainchild of a network architect with a deep love for all things IT. Built by one person with a knack for diagnostics and a relentless curiosity for networking puzzles, Trace Warrior provides practical tools that solve real-world problems. If you want to dig deeper into the mind behind the code, check out my <a href="https://www.linkedin.com/in/lukethomas/" target="_blank" rel="noopener noreferrer">LinkedIn profile</a>.</p>
        </div>

        <div className="values-section">
          <h2>Our Values</h2>
          <ul>
            <li><strong>Innovation:</strong> We strive to innovate constantly, ensuring our tools remain cutting-edge and useful for the evolving demands of network management.</li>
            <li><strong>Reliability:</strong> Our tools are crafted with reliability in mind. We know that network diagnostics are critical, and our solutions are built to provide accurate, dependable results.</li>
            <li><strong>User-Centric Design:</strong> We prioritize our users' needs, ensuring our tools are as easy to use as they are powerful.</li>
          </ul>
        </div>

        <div className="contact-section">
          <h2>Get in Touch</h2>
          <p>If you have any questions, suggestions, or would like to collaborate with us, feel free to reach out via our <a href="/contact">Contact Us</a> page. We love hearing from our users and are always looking for ways to improve.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
