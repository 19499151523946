// src/pages/PortChecker.js
import React, { useState } from 'react';
import './PortChecker.css';
import ToolCard from '../components/ToolCard'; // Import ToolCard to display other tools
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/whois-lookup",
    image: "/images/whois-lookup.png",
    title: "Whois Lookup",
    description: "Retrieve domain registration information."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }
];

const PortChecker = () => {
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleHostChange = (e) => {
    setHost(e.target.value);
  };

  const handlePortChange = (e) => {
    setPort(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResult('');

    try {
      // Simulate a port check request
      const response = await fakePortCheckRequest(host, port);
      setResult(response);
    } catch (error) {
      setResult('Error: Unable to check the port status.');
    }

    setIsLoading(false);
  };

  // Fake port check request function for demonstration purposes
  const fakePortCheckRequest = async (host, port) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(`Port ${port} on ${host} is open.`);
      }, 2000);
    });
  };

  return (
    <div className="tool-page-container">
      <Helmet>
        <title>Port Checker - Trace Warrior</title>
        <meta name="description" content="Check open ports on your server using the Port Checker tool. Identify potential vulnerabilities and secure your network." />
        <link rel="canonical" href="https://tracewarrior.com/port-checker" />
      </Helmet>
      <div className="tool-container">
        <div className="tool-header">
          <h1>Port Checker</h1>
          <p>Enter a hostname or IP address and a port number to check if the port is open.</p>
        </div>

        <div className="tool-content">
          <form onSubmit={handleSubmit} className="tool-form">
            <input
              type="text"
              className="input-field"
              value={host}
              onChange={handleHostChange}
              placeholder="Enter hostname or IP, e.g., example.com or 192.168.0.1"
            />
            <input
              type="text"
              className="input-field"
              value={port}
              onChange={handlePortChange}
              placeholder="Enter port number, e.g., 80"
            />
            <button className="submit-button" type="submit" disabled={isLoading}>
              {isLoading ? 'Checking...' : 'Check Port'}
            </button>
          </form>

          {result && (
            <div className="results">
              <h2>Result:</h2>
              <textarea className="output-area" readOnly value={result} rows="3" />
            </div>
          )}
        </div>
      </div>

      {/* Explore Other Tools Section */}
      <div className="explore-tools-section">
        <h2>Explore More Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortChecker;
