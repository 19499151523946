// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; 

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);

    // Add or remove the 'menu-open' class from the body to control dimming
    if (!isMenuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  };

  return (
    <>
      <div className={`overlay ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}></div>

      <header className="header">
        <div className="header-container">
          <img
            src={process.env.PUBLIC_URL + "/images/trace-warrior-logo.png"}
            alt="Trace Warrior Logo"
            className="logo"
          />
            <p class="branding-tagline">Trace Warrior - Your Network Diagnostic Toolkit</p>
          <div className="hamburger-icon" onClick={toggleMenu}>
            &#9776;
          </div>
        </div>

        <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <span className="close-btn" onClick={toggleMenu}>&times;</span>
          <ul>
            <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li><Link to="/mac-lookup" onClick={toggleMenu}>MAC Address Lookup</Link></li>
            <li><Link to="/subnet-calculator" onClick={toggleMenu}>Subnet Calculator</Link></li>
            <li><Link to="/whois-lookup" onClick={toggleMenu}>Whois Lookup</Link></li>
            <li><Link to="/reverse-dns-lookup" onClick={toggleMenu}>Reverse DNS Lookup</Link></li>
            <li><Link to="/http-header-checker" onClick={toggleMenu}>HTTP Header Checker</Link></li>
            <li><Link to="/url-encoder-decoder" onClick={toggleMenu}>URL Encoder/Decoder</Link></li>
            <li><Link to="/ping-test" onClick={toggleMenu}>Ping Test</Link></li>
            <li><Link to="/port-checker" onClick={toggleMenu}>Port Checker</Link></li>
            <li><Link to="/about-us" onClick={toggleMenu}>About Us</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>Contact Us</Link></li>
            <li><Link to="/privacy-policy" onClick={toggleMenu}>Privacy Policy</Link></li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
