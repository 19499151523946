// src/pages/MACLookup.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet to manage the <head> section
import ToolCard from '../components/ToolCard'; // Import ToolCard to display other tools
import './MACLookup.css';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/whois-lookup",
    image: "/images/whois-lookup.png",
    title: "Whois Lookup",
    description: "Retrieve domain registration information."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }

];

const faqs = [
  { question: "What is a MAC address?", answer: "A unique Layer 2 identifier with two parts: manufacturer ID and device-specific ID." },
  { question: "Why does a MAC address matter?", answer: "It directs network traffic to the right device, like an address at Layer 2 of the OSI model." },
  { question: "What does 'burned-in' mean?", answer: "It’s factory-set in hardware, making it a stable device identifier." },
  { question: "How accurate is the lookup?", answer: "Highly accurate—based on a trusted, up-to-date database." },
  { question: "Can MAC addresses be faked?", answer: "Yes, through spoofing to mask identity. Regular checks help spot this." },
  { question: "How can the MAC Lookup tool help me?", answer: "It identifies device manufacturers, helping to verify and manage network devices." }
];

const MACLookup = () => {
  const [mac, setMac] = useState('');
  const [vendor, setVendor] = useState('');

  const handleChange = (e) => {
    setMac(e.target.value);
  };

  const handleLookup = async (e) => {
    e.preventDefault();
    if (mac.trim() === '') {
      setVendor("Please enter a MAC address.");
      return;
    }
    try {
      const response = await fetch(`/api/mac-lookup?mac=${mac}`);
      const data = await response.json();
      if (response.ok) {
        setVendor(data.vendor);
      } else {
        setVendor(data.error || "An error occurred");
      }
    } catch (error) {
      setVendor("Error fetching data. Please try again.");
    }
  };

  return (
    <div className="tool-page-container">
      <Helmet>
        <title>MAC Address Lookup - Trace Warrior</title>
        <meta name="description" content="Use the MAC Address Lookup tool from Trace Warrior to identify device manufacturers by MAC address." />
        <link rel="canonical" href="https://tracewarrior.com/mac-lookup" />
      </Helmet>

      <div className="tool-container">
        <div className="tool-header">
          <h1>MAC Address Lookup</h1>
          <p>Find the manufacturer of a device based on its MAC address. Enter a MAC address below to get started.</p>
        </div>

        <div className="tool-content">
          <form onSubmit={handleLookup} className="tool-form">
            <input
              className="input-area"
              type="text"
              value={mac}
              onChange={handleChange}
              placeholder="Enter MAC address, e.g., 68:db:f5:52:6c"
            />
            <button className="submit-button" type="submit">Lookup</button>
          </form>

          {vendor && (
            <div className="results">
              <h2>Result:</h2>
              <textarea className="output-area" readOnly value={`Vendor: ${vendor}`} rows="3" />
            </div>
          )}
        </div>
        {/* FAQ Section */}
        <div className="faq-section">
          <h2>Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="explore-tools-section">
        <h2>Explore Our Other Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MACLookup;
