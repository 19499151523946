// src/pages/DNSLookup.js
import React, { useState } from 'react';
import ToolCard from '../components/ToolCard'; // Importing the ToolCard component to use it below
import './DNSLookup.css';
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/subnet-calculator",
    image: "/images/subnet-calculator.png",
    title: "Subnet Calculator",
    description: "Helps divide your network into subnets with ease."
  },
  {
    link: "/whois-lookup",
    image: "/images/whois-lookup.png",
    title: "Whois Lookup",
    description: "Retrieve domain registration information."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }
];

const DNSLookup = () => {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleLookup = (e) => {
    e.preventDefault();
    // Placeholder logic for DNS lookup
    setResult(`DNS records for: ${input}`);
  };

  return (
    <div className="tool-page-container">
      <Helmet>
        <title>DNS Lookup - Trace Warrior</title>
        <meta name="description" content="Retrieve DNS records of a domain using the DNS Lookup tool. Gain insights into the DNS setup of any domain." />
        <link rel="canonical" href="https://tracewarrior.com/dns-lookup" />
      </Helmet>
      <div className="tool-container">
        <div className="tool-header">
          <h1>DNS Lookup Tool</h1>
          <p>Retrieve DNS records for a domain. Enter a domain name below to get started.</p>
        </div>

        <div className="tool-content">
          <form onSubmit={handleLookup} className="tool-form">
            <input
              className="input-area"
              type="text"
              value={input}
              onChange={handleChange}
              placeholder="Enter domain, e.g., example.com"
            />
            <button className="submit-button" type="submit">Lookup</button>
          </form>

          {result && (
            <div className="results">
              <h2>Result:</h2>
              <textarea className="output-area" readOnly value={result} rows="5" />
            </div>
          )}
        </div>
      </div>

      {/* Adding the Tool Grid Below the Main Tool */}
      <div className="explore-tools-section">
        <h2>Explore Our Other Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DNSLookup;
