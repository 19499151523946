// src/pages/URLEncoderDecoder.js
import React, { useState } from 'react';
import './URLEncoderDecoder.css';
import { Helmet } from 'react-helmet';

const URLEncoderDecoder = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [isEncoding, setIsEncoding] = useState(true);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleToggle = () => {
    setIsEncoding(!isEncoding);
    setInput('');
    setOutput('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEncoding) {
      setOutput(encodeURIComponent(input));
    } else {
      try {
        setOutput(decodeURIComponent(input));
      } catch (error) {
        setOutput('Invalid URL input');
      }
    }
  };

  return (
    <div className="tool-container">
      <Helmet>
        <title>URL Encoder/Decoder - Trace Warrior</title>
        <meta name="description" content="Ensure proper URL formatting and readability with our URL Encoder/Decoder tool. Easily convert URLs to safe formats." />
        <link rel="canonical" href="https://tracewarrior.com/url-encoder-decoder" />
      </Helmet>
      <div className="tool-header">
        <h1>URL Encoder/Decoder Tool</h1>
        <p>Encode or decode a URL to ensure proper formatting for use in web browsers.</p>
      </div>
      
      <div className="tool-content">
        <button className="toggle-button" onClick={handleToggle}>
          {isEncoding ? 'Switch to Decode' : 'Switch to Encode'}
        </button>
        
        <form onSubmit={handleSubmit} className="tool-form">
          <textarea
            className="input-area"
            value={input}
            onChange={handleChange}
            placeholder={isEncoding ? 'Enter text to encode...' : 'Enter encoded URL to decode...'}
            rows="5"
          />
          <button className="submit-button" type="submit">
            {isEncoding ? 'Encode' : 'Decode'}
          </button>
        </form>

        {output && (
          <div className="results">
            <h2>Result:</h2>
            <textarea className="output-area" readOnly value={output} rows="5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default URLEncoderDecoder;
