
import React, { useState } from 'react';
import ToolCard from '../components/ToolCard'; // Import ToolCard to display other tools
import './SubnetCalculator.css';
import { Helmet } from 'react-helmet';

const tools = [
  {
    link: "/mac-lookup",
    image: "/images/mac-lookup.png",
    title: "MAC Address Lookup",
    description: "Identify the manufacturer associated with a specific MAC address."
  },
  {
    link: "/whois-lookup",
    image: "/images/whois-lookup.png",
    title: "Whois Lookup",
    description: "Retrieve domain registration information."
  },
  {
    link: "/reverse-dns-lookup",
    image: "/images/reverse-dns.png",
    title: "Reverse DNS Lookup",
    description: "Find the domain name associated with an IP address."
  },
  {
    link: "/http-header-checker",
    image: "/images/http-header-checker.png",
    title: "HTTP Header Checker",
    description: "Inspect HTTP headers for troubleshooting web servers."
  },
  {
    link: "/url-encoder-decoder",
    image: "/images/url-encoder-decoder.png",
    title: "URL Encoder/Decoder",
    description: "Ensure proper URL formatting for readability."
  },
  {
    link: "/ping-test",
    image: "/images/ping-test.png",
    title: "Ping Test",
    description: "Test the reachability of hosts on a network."
  },
  {
    link: "/port-checker",
    image: "/images/port-checker.png",
    title: "Port Checker",
    description: "Check open ports on your server."
  },
  {
    link: "/dns-lookup",
    image: "/images/dns-lookup.png",
    title: "DNS Lookup",
    description: "Retrieve DNS records of a domain."
  },
  {
    link: "/base64-encoder-decoder",
    image: "/images/base64-encoder-decoder.png",
    title: "Base64 Encoder/Decoder",
    description: "Encode or decode data to/from Base64."
  }
];

const faqs = [
  { question: "What is subnetting?", answer: "Subnetting is the practice of dividing a network into smaller, more efficient sub-networks." },
  { question: "Why is subnetting useful?", answer: "Subnetting improves network management, increases security, and reduces broadcast traffic." },
  { question: "How do I choose the right subnet mask?", answer: "The subnet mask depends on the number of subnets and hosts required. A smaller subnet mask provides more hosts per subnet." },
  { question: "What is a subnet ID?", answer: "A subnet ID is the unique identifier for a subnet, derived from the network address and subnet mask." },
  { question: "How does the Subnet Calculator help me?", answer: "The Subnet Calculator helps calculate subnet addresses, broadcast addresses, and the range of host addresses for a given IP and subnet mask." }
];

const SubnetCalculator = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [subnetMask, setSubnetMask] = useState('');
  const [result, setResult] = useState(null);
  const [activeTab, setActiveTab] = useState('subnet');

  const handleIPAddressChange = (e) => {
    setIpAddress(e.target.value);
  };

  const handleSubnetMaskChange = (e) => {
    setSubnetMask(e.target.value);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const calculatedResult = calculateSubnet(ipAddress, subnetMask);
      setResult(calculatedResult);
    } catch (error) {
      setResult('Error: Please enter a valid IP address and subnet mask.');
    }
  };

  const calculateSubnet = (ip, mask) => {
    // Validate inputs
    if (!validateIPAddress(ip) || !validateIPAddress(mask)) {
      throw new Error('Invalid input');
    }

    const ipParts = ip.split('.').map(Number);
    const maskParts = mask.split('.').map(Number);

    // Calculate network address
    const networkAddressParts = ipParts.map((part, index) => part & maskParts[index]);
    const networkAddress = networkAddressParts.join('.');

    // Calculate number of hosts
    const maskBits = maskParts.reduce((acc, part) => acc + part.toString(2).replace(/0/g, '').length, 0);
    const numberOfHosts = Math.pow(2, 32 - maskBits) - 2; // Total hosts in the subnet

    // Calculate broadcast address
    const invertedMaskParts = maskParts.map(part => 255 - part);
    const broadcastAddressParts = networkAddressParts.map((part, index) => part | invertedMaskParts[index]);
    const broadcastAddress = broadcastAddressParts.join('.');

    // Calculate range of host addresses
    const firstHost = [...networkAddressParts];
    firstHost[3] += 1;
    const firstHostAddress = firstHost.join('.');

    const lastHost = [...broadcastAddressParts];
    lastHost[3] -= 1;
    const lastHostAddress = lastHost.join('.');

    // Determine network class
    let networkClass = '';
    if (ipParts[0] >= 0 && ipParts[0] <= 127) {
      networkClass = 'Class A';
    } else if (ipParts[0] >= 128 && ipParts[0] <= 191) {
      networkClass = 'Class B';
    } else if (ipParts[0] >= 192 && ipParts[0] <= 223) {
      networkClass = 'Class C';
    } else if (ipParts[0] >= 224 && ipParts[0] <= 239) {
      networkClass = 'Class D';
    } else if (ipParts[0] >= 240 && ipParts[0] <= 255) {
      networkClass = 'Class E';
    }

    // Calculate wildcard mask
    const wildcardMaskParts = maskParts.map(part => 255 - part);
    const wildcardMask = wildcardMaskParts.join('.');

    return {
      networkAddress,
      subnetMask: mask,
      broadcastAddress,
      numberOfHosts,
      firstHostAddress,
      lastHostAddress,
      networkClass,
      wildcardMask,
    };
  };

  const validateIPAddress = (ip) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
  };

  return (
    <div className="tool-page-container">
      <Helmet>
        <title>Subnet Calculator - Trace Warrior</title>
        <meta name="description" content="Calculate subnet sizes with the Subnet Calculator for efficient network planning and management." />
        <link rel="canonical" href="https://tracewarrior.com/subnet-calculator" />
      </Helmet>
      <div className="tool-container">
        <div className="tool-header">
          <h1>Subnet Calculator</h1>
          <p>Enter an IP address and subnet mask to calculate the network address and other subnet details.</p>
        </div>

        <div className="tool-content">
          <form onSubmit={handleSubmit} className="tool-form">
            <input
              type="text"
              className="input-field same-width-field"
              style={{ width: '100%' }}
              value={ipAddress}
              onChange={handleIPAddressChange}
              placeholder="Enter IP address, e.g., 192.168.0.1"
            />
            <select
              className="input-field same-width-field subnet-mask-dropdown"
              style={{ width: '100%' }}
              onChange={handleSubnetMaskChange}
              value={subnetMask}
            >
              <option value="">Select subnet mask...</option>
              <option value="255.255.255.255">255.255.255.255 /32</option>
              <option value="255.255.255.254">255.255.255.254 /31</option>
              <option value="255.255.255.252">255.255.255.252 /30</option>
              <option value="255.255.255.248">255.255.255.248 /29</option>
              <option value="255.255.255.240">255.255.255.240 /28</option>
              <option value="255.255.255.224">255.255.255.224 /27</option>
              <option value="255.255.255.192">255.255.255.192 /26</option>
              <option value="255.255.255.128">255.255.255.128 /25</option>
              <option value="255.255.255.0">255.255.255.0 /24</option>
              <option value="255.255.254.0">255.255.254.0 /23</option>
              <option value="255.255.252.0">255.255.252.0 /22</option>
              <option value="255.255.248.0">255.255.248.0 /21</option>
              <option value="255.255.240.0">255.255.240.0 /20</option>
              <option value="255.255.224.0">255.255.224.0 /19</option>
              <option value="255.255.192.0">255.255.192.0 /18</option>
              <option value="255.255.128.0">255.255.128.0 /17</option>
              <option value="255.255.0.0">255.255.0.0 /16</option>
              <option value="255.254.0.0">255.254.0.0 /15</option>
              <option value="255.252.0.0">255.252.0.0 /14</option>
              <option value="255.248.0.0">255.248.0.0 /13</option>
              <option value="255.240.0.0">255.240.0.0 /12</option>
              <option value="255.224.0.0">255.224.0.0 /11</option>
              <option value="255.192.0.0">255.192.0.0 /10</option>
              <option value="255.128.0.0">255.128.0.0 /9</option>
              <option value="255.0.0.0">255.0.0.0 /8</option>
              <option value="254.0.0.0">254.0.0.0 /7</option>
              <option value="252.0.0.0">252.0.0.0 /6</option>
              <option value="248.0.0.0">248.0.0.0 /5</option>
              <option value="240.0.0.0">240.0.0.0 /4</option>
              <option value="224.0.0.0">224.0.0.0 /3</option>
              <option value="192.0.0.0">192.0.0.0 /2</option>
              <option value="128.0.0.0">128.0.0.0 /1</option>
              {/* Add more subnet masks as needed */}
            </select>
            <button className="submit-button" type="submit">
              Calculate
            </button>
          </form>

          {result && typeof result === 'object' && (
            <div className="results">
              <h2>Result:</h2>
              <table className="result-table">
                <thead>
                  <tr>
                    <th>Subnet ID</th>
                    <th>Host Addresses</th>
                    <th>Subnet Broadcast</th>
                    <th>Network Class</th>
                    <th>Wildcard Mask</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{result.networkAddress}</td>
                    <td>{result.firstHostAddress} - {result.lastHostAddress}</td>
                    <td>{result.broadcastAddress}</td>
                    <td>{result.networkClass}</td>
                    <td>{result.wildcardMask}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {typeof result === 'string' && (
            <div className="results">
              <h2>Result:</h2>
              <p>{result}</p>
            </div>
          )}
        </div>

        {/* FAQ Section */}
        <div className="faq-section">
          <h2>Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="explore-tools-section">
        <h2>Explore Our Other Tools</h2>
        <div className="tool-grid">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubnetCalculator;
